import {
  Grid,
  Typography,
  MenuItem,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import FormInput from "../shareComponents/form/FormInput";
import { getAllDBConstants } from "../../services/dbConstants";
import { InstituteDetails } from "../../utils/types/services/user";
import { useQueries } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { useMemo, useState } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import UpdateInstituteInfo from "../shareComponents/modal/UpdateInstituteInfo";
import useModal from "../../hooks/useModal";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { mobileNumberPrefix } from "../../utils/constants/general";
import NoneDisplayInput from "../shareComponents/input/NonDisplayForwardRefInput";

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
  paddingBottom: "2px",
} as const;

interface AccountInfoProps {
  isInstituteInfo: boolean;
  mode: string;
}

const AccountInfo = (props: AccountInfoProps) => {
  const { isInstituteInfo, mode } = props;
  const dummyInstProps = {
    instituteName: "",
    instituteType: -1,
    instituteAddress: "",
    instituteDistrict: "-",
    instituteMobile: "",
    instituteMobilePrefix: mobileNumberPrefix,
    Sunday: "",
    Monday: "",
    Tuesday: "",
    Wednesday: "",
    Thursday: "",
    Friday: "",
    Saturday: "",
  };

  const { t } = useTranslation();
  const { control, getValues, setValue, watch, trigger } = useFormContext();
  const instituteDetails = getValues("instituteDetails");
  const [updateIndex, setUpdateIndex] = useState<number>(0);
  const [instDet, setInstDet] = useState<InstituteDetails[]>([
    { ...dummyInstProps },
    { ...dummyInstProps },
    { ...dummyInstProps },
  ]);
  const MOBILE_PHRASE = "singlePharse.contactDetails";
  const [editMobileNumber, setEditMobileNumber] = useState<string | null>(null);

  setTimeout(() => {
    setInstDet(instituteDetails);
  }, 0);

  const [{ data: dbConstants }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.DB_CONSTANTS],
        queryFn: getAllDBConstants,
      },
    ],
  });

  const instituteTypes = dbConstants?.data?.instituteTypes ?? [];
  const instituteNames = dbConstants?.data?.instituteNames ?? [];
  const districtList = dbConstants?.data?.districtList ?? [];

  const defaultSelectItems = [
    {
      id: -1,
      displayText: "-",
    },
  ];

  const instituteNamesDisplay = useMemo(
    () =>
      instituteNames.filter(
        (e) => e.instituteType === getValues("instituteType")
      ),
    [watch("instituteType"), instituteNames, getValues]
  );

  const changeAddress = (val: string, i: number) => {
    let temp = instituteDetails;
    temp[i].instituteAddress = val;
    setValue("instituteDetails", temp);
  };

  const {
    isOpen: isUpdateOpen,
    closeModal: closeUpdateDrawer,
    openModal: openUpdateDrawer,
  } = useModal();

  const handleInstituteHours = (details: InstituteDetails, index: number) => {
    setUpdateIndex(index);
    openUpdateDrawer();
  };

  const handleUpdateSaveInst = (details: InstituteDetails, index: number) => {
    let tempDet = instDet;
    tempDet[index] = details;
    setInstDet(tempDet);
    setValue("instituteDetails", tempDet);
  };

  const getInstTypeText = (code: number) => {
    let tempType = instituteTypes.filter((ee) => ee.id === code);
    return tempType[0] ? tempType[0].displayText : code;
  };

  const changeMobileNumber = (val: string, i: number) => {
    let temp = instDet;
    temp[i].instituteMobile = val;
    setEditMobileNumber(val); // Update the edited mobile number state
    setInstDet(temp);
  };

  const handleAddressChange = (index: number) => {
    trigger(`instituteDetails.${index}.instituteDistrict`);
  };

  const handleDistrictChange = (index: number) => {
    trigger(`instituteDetails.${index}.instituteAddress`);
  };

  const isWorkingHoursClosed = (inst: any) => {
    if (
      inst.Sunday === "Closed" &&
      inst.Monday === "Closed" &&
      inst.Tuesday === "Closed" &&
      inst.Wednesday === "Closed" &&
      inst.Thursday === "Closed" &&
      inst.Friday === "Closed" &&
      inst.Saturday === "Closed"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Grid item xs={12}>
      <UpdateInstituteInfo
        isOpen={isUpdateOpen}
        handleClose={closeUpdateDrawer}
        instDet={instDet}
        updateIndex={updateIndex}
        handleUpdateSave={handleUpdateSaveInst}
      />
      {!isInstituteInfo && (
        <Grid
          container
          justifyContent={"flex-start"}
          direction={"row"}
          alignItems={"center"}
        >
          <Grid item xs={12}>
            <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
              Institute Info
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="instititionType"
              label="Institute Type"
              fullWidth
              type="text"
              disabled
            />
          </Grid>

          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="instituteName"
              label="Institute Name"
              fullWidth
              type="text"
              disabled
            />
          </Grid>

          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="address"
              label="Institute Address"
              fullWidth
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="institteDistrict"
              label="Institute District"
              fullWidth
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="instituteMobile"
              label={t("singlePharse.contactDetails")}
              fullWidth
              type="text"
              disabled
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingX: "1%" }}>
            <FormInput
              control={control}
              name="hours"
              label="Institute Working Hours"
              fullWidth
              type="text"
              disabled
            />
          </Grid>
        </Grid>
      )}
      {isInstituteInfo && (
        <Grid
          container
          justifyContent={"flex-start"}
          direction={"row"}
          alignItems={"center"}
        >
          {instDet &&
            instDet.map((institute: InstituteDetails, i: number) => (
              <Grid item xs={12} key={i}>
                {institute.instituteName !== null &&
                  institute.instituteName !== "" && (
                    <Grid item xs={12} key={i}>
                      <Typography
                        variant="h6"
                        align="left"
                        sx={{ fontWeight: "bold" }}
                      >
                        Institute {i + 1}
                      </Typography>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={listHeader}
                          align="left"
                        >
                          Institute Type
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={listValue}
                          align="left"
                        >
                          {getInstTypeText(institute.instituteType)}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <Typography
                          variant="subtitle2"
                          sx={listHeader}
                          align="left"
                        >
                          Institute Name
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          sx={listValue}
                          align="left"
                        >
                          {institute.instituteName}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        {mode === "add" && (
                          <Box>
                            <Box sx={{ mb: 2 }}>
                              <Typography
                                variant="subtitle2"
                                sx={listHeader}
                                align="left"
                              >
                                Institute Address
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={listValue}
                                align="left"
                              >
                                {institute.instituteAddress}
                              </Typography>
                            </Box>
                            <Box sx={{ mb: 2 }}>
                              <Typography
                                variant="subtitle2"
                                sx={listHeader}
                                align="left"
                              >
                                Institute District
                              </Typography>
                              <Typography
                                variant="subtitle1"
                                sx={listValue}
                                align="left"
                              >
                                {institute.instituteDistrict
                                  ? institute.instituteDistrict
                                  : "-"}
                              </Typography>
                            </Box>
                          </Box>
                        )}
                        {mode === "edit" && (
                          <Box>
                            <FormInput
                              control={control}
                              name={`instituteDetails.${i}.instituteAddress`}
                              label={t("singlePharse.instituteAddress", {
                                context: "input",
                              })}
                              variant="outlined"
                              type="text"
                              fullWidth
                              disabled
                              onKeyUp={() => handleAddressChange(i)}
                            />

                            <FormInput
                              select
                              control={control}
                              name={`instituteDetails.${i}.instituteDistrict`}
                              label="Institute District"
                              sx={{ width: "100%" }}
                              onKeyUp={() => handleDistrictChange(i)}
                            >
                              {defaultSelectItems
                                .concat(districtList)
                                .map(({ id, displayText }) => (
                                  <MenuItem key={id} value={displayText}>
                                    {displayText}
                                  </MenuItem>
                                ))}
                            </FormInput>
                          </Box>
                        )}
                      </Box>

                      <Box>
                        {/* <Typography
                          variant="subtitle2"
                          sx={listHeader}
                          align="left"
                        >
                          Contact Detail
                        </Typography> */}
                        <Grid container>
                          <Grid item xs={12} container direction="row">
                            <PhoneInputWithCountry
                              international
                              addInternationalOption={false}
                              name={`instituteDetails.${i}.instituteMobilePrefix`}
                              label={t(MOBILE_PHRASE)}
                              control={control}
                              countries={["HK", "MO"]}
                              defaultCountry={
                                watch(
                                  `instituteDetails.${i}.instituteMobilePrefix`
                                ) === mobileNumberPrefix
                                  ? "HK"
                                  : "MO"
                              }
                              placeholder={t(MOBILE_PHRASE)}
                              inputComponent={NoneDisplayInput}
                              disabled={mode === "add"}
                            />
                            <FormInput
                              control={control}
                              name={`instituteDetails.${i}.instituteMobile`}
                              label={t(MOBILE_PHRASE)}
                              variant="outlined"
                              sx={{ flexGrow: 1 }}
                              type="tel-local"
                              disabled={mode === "add"}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box sx={{ mt: 2, mb: 2 }}>
                        <Grid sx={{ display: "flex" }}>
                          <Typography
                            variant="subtitle2"
                            sx={listHeader}
                            align="left"
                          >
                            Institute Working Hours
                          </Typography>
                          {mode === "edit" && (
                            <IconButton
                              sx={{ padding: 0, pl: 4 }}
                              onClick={() => handleInstituteHours(institute, i)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          )}
                        </Grid>
                        {!isWorkingHoursClosed(institute) && (
                          <Box>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Sun : {institute.Sunday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Mon : {institute.Monday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Tue : {institute.Tuesday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Wed : {institute.Wednesday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Thu : {institute.Thursday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Fri : {institute.Friday}
                            </Typography>
                            <Typography
                              variant="subtitle1"
                              sx={listValue}
                              align="left"
                            >
                              Sat : {institute.Saturday}
                            </Typography>
                          </Box>
                        )}
                        {isWorkingHoursClosed(institute) && (
                          <Box>
                            {mode === "add" && (
                              <Typography
                                variant="subtitle1"
                                sx={listValue}
                                align="left"
                              >
                                N/A: Working hours hidden in patient app. You
                                can edit this in your profile settings later if
                                necessary.
                              </Typography>
                            )}
                            {mode === "edit" && (
                              <Typography
                                variant="subtitle1"
                                sx={listValue}
                                align="left"
                              >
                                N/A: Working hours hidden in patient app. Click
                                edit icon to revert it.
                              </Typography>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  )}
              </Grid>
            ))}
        </Grid>
      )}
    </Grid>
  );
};

export default AccountInfo;
