import { createTheme, ThemeProvider, CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
  ArcElement,
  SubTitle,
  BarElement,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-luxon";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./i18n";
import Layout from "./Layouts";
import ReactGA from "react-ga4";
import ErrorBoundary from "./utils/customProvider/ErrorBoundary";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID ?? "", {
  testMode: false,        // For production- false, For Development- true 
});

const NUM_1000 = 1000;
const NUM_60 = 60;
const NUM_24 = 24;
const NUM_2 = 2;
const NUM_30000 = 30000;
const NUM_6 = 6;

const twentyFourHoursInMs = NUM_1000 * NUM_60 * NUM_60 * NUM_24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
      retryDelay: (attemptIndex) =>
        Math.min(NUM_1000 * NUM_2 * attemptIndex, NUM_30000),
    },
    mutations: {
      cacheTime: 0,
    },
  },
});

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  BarElement,
  ArcElement,
  ChartDataLabels
);

const App = () => {
  const theme = createTheme({
    // https://mui.com/material-ui/customization/default-theme/?expand-path=$.palette
    spacing: (value: number) => value * NUM_6,
    palette: {
      common: {
        white: "#fff",
        black: "#000",
      },
      text: {
        primary: "rgba(0,0,0,0.87)",
        secondary: "rgba(0,0,0,0.6)",
        disabled: "rgba(0,0,0,0.38)",
      },
      primary: {
        main: "#0C41CD",
        light: "#E5EFFA",
        dark: "#1565c0",
        contrastText: "#fff",
      },
      info: {
        main: "#0288d1",
      },
      success: {
        main: "#2e7d32",
      },
      error: {
        main: "#d32f2f",
      },
      action: {
        hover: "rgba(0,0,0,0.04)",
      },
      background: { default: "#F5F6FA", paper: "#fff" },
      divider: "rgba(0,0,0,0.12)",
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            margin: 0,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
      MuiTextField: {
        defaultProps: {
          autoComplete: "off",
        },
        styleOverrides: {
          root: {
            margin: 0,
            marginTop: "5px",
          },
        },
      },
    },
  });
  sessionStorage.setItem("showPspToHCP", "true");

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <BrowserRouter>
            <ErrorBoundary>
              <CssBaseline />
              <Layout />
              <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
            </ErrorBoundary>
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
