import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import FormInput from "../../shareComponents/form/FormInput";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import NoneDisplayInput from "../../shareComponents/input/NonDisplayForwardRefInput";
import { UserFormState } from ".";
import { mobileNumberPrefix } from "../../../utils/constants/general";

const PersonalInfo = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContext<UserFormState>();
  const MOBILE_PHRASE = "singlePharse.mobile";
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      direction={"row"}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
          {t("singlePharse.personalInfo")}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{ paddingX: "1%" }}>
        <FormInput
          control={control}
          name="firstName"
          label={t("singlePharse.firstName")}
          fullWidth
          type="text"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{ paddingX: "1%" }}>
        <FormInput
          control={control}
          name="lastName"
          label={t("singlePharse.lastName")}
          fullWidth
          type="text"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{ paddingX: "1%" }}>
        <FormInput
          control={control}
          name="nickName"
          label={t("singlePharse.nickName")}
          fullWidth
          type="text"
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} sx={{ paddingX: "1%" }}>
        <FormInput
          disabled
          control={control}
          name="email"
          label={t("singlePharse.email")}
          fullWidth
          autoComplete="username"
          type="email"
        />
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={12}
        sx={{ paddingX: "1%" }}
        flexDirection="row"
      >
        <PhoneInputWithCountry
          control={control}
          name="mobilePrefix"
          label={t(MOBILE_PHRASE)}
          international
          addInternationalOption={false}
          countries={["HK", "MO"]}
          defaultCountry={
            watch("mobilePrefix") === mobileNumberPrefix ? "HK" : "MO"
          }
          placeholder={t(MOBILE_PHRASE)}
          inputComponent={NoneDisplayInput}
        />
        <FormInput
          control={control}
          name="mobileLocal"
          label={t(MOBILE_PHRASE)}
          variant="outlined"
          sx={{ flexGrow: 1 }}
          type="tel-local"
        />
      </Grid>
    </Grid>
  );
};

export default PersonalInfo;
